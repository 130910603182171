import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Murals from "../components/GalleryContent/MuralsGalleryContent"
import Chairs from "../components/GalleryContent/ChairsGalleryContent"
import Stools from "../components/GalleryContent/StoolsGalleryContent"
import Tables from "../components/GalleryContent/TablesGalleryContent"

const HeaderFurniture = () => (
  <Layout>
    <SEO title="Furniture" />
    <h1>Furniture</h1>
    <Murals />
    <Chairs />
    <Stools />
    <Tables />
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default HeaderFurniture
